import { getStockState } from '@/helpers/inventory';
import { Company, Order, Staff } from '@/types/schema';
import postCloverMeteredBilling from '@/utils/api/postCloverMeteredBilling';
import axios from 'axios';

export async function swipeCardAndMeteredBilling( order: Order, staff: Staff ) {
	if ( window?.Android ) {
		if ( typeof order?.externalId === 'string' ) {
			window.Android.onPayOrder( order?.externalId );
		}
	}
	await postCloverMeteredBilling( {
		orderId  : order.id,
		gatewayId: order?.gateway?.id || order.companyLocation?.gateway?.id,
		eventType: 'Charge on Clover',
		count    : 1,
		key      : 'swipeCard',
		staffId  : staff?.id,
		clientId : order.client?.id,
	} )
		.catch( () => [] );
	
}

export type StockMethod = 'manualSyncStock' | 'manualSendStock';

export async function updateStock(
	order?: Order,
	company?: Company | null,
	stockMethod?: StockMethod,
	enqueueSnackbar?: any,
	action?: string,
) {
	if ( !order ) return;
	const companyMetadata = company?.metadata || order.company?.metadata;
	const { allStocked } = getStockState( order.lineItems );
	
	if ( companyMetadata?.stockMethod === stockMethod && !allStocked ) {
		try {
			const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/user/inventory/autoStockCommerce`, { id: order.id, action } );
			if ( data?.updated ) {
				await enqueueSnackbar?.( 'Stock updated successfully', { variant: 'success' } );
			}
		} catch ( e ) {
			console.error( e );
			await enqueueSnackbar?.( 'Failed updating stock', { variant: 'error' } );
		}
		
	}
}

