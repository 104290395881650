import { GatewayBase } from '@/generated/graphql';
import currencyFormat from '@/helpers/currencyFormat';
import { getUserInfo } from '@/providers/auth/useUserInfo';
import { Address, Company, LineItem, Order, Payment, Price, Staff } from '@/types/schema';
import axios from 'axios';
import { flatten, isEmpty, partition, round, sum } from 'lodash-es';

type Params = {
	type: string,
	amount: number,
	fee: number,
	tip: number,
	note: string,
	signature: string | undefined | null,
	orderId: string,
	gatewayId?: string,
	companyId: string,
	employeeId?: string,
	houseAccountId?: string,
	metadata: any,
	staffExternalId: string | null,
	staffId?: string,
	paymentId?: string,
	memberCardNumber?: string,
	cardHolderName?: string,
	cardHolderPhone?: string,
	cardFirstSix?: string,
	cardLastFour?: string,
	isPaymentLink?: boolean,
	payerId?: string,
	payerName?: string,
	payerFirstName?: string,
	payerLastName?: string,
	saveCard?: boolean,
	args?,
	cardToken?: string,
	bankRoutingNbr?: string,
	bankAccountNbr?: string,
	bankAccountType?: string,
	isClientPage?: boolean,
	clientAddress?: Address,
	payerPostal?: string,
	payerCity?: string,
	payerState?: string,
	payerLine1?: string,
	invoiceNumber?: string,
	scheduledPaymentId?: string
	achToken?: string,
	selectedScheduledPayments?: Record<string, boolean>
};

export async function makePayment( {
	type,
	amount,
	fee,
	tip,
	note,
	signature,
	orderId,
	gatewayId,
	companyId,
	employeeId,
	houseAccountId,
	metadata,
	staffExternalId,
	staffId,
	paymentId,
	memberCardNumber,
	cardHolderName,
	cardHolderPhone,
	cardFirstSix,
	cardLastFour,
	isPaymentLink,
	payerId,
	payerName,
	payerFirstName,
	payerLastName,
	saveCard,
	args,
	cardToken,
	bankRoutingNbr,
	bankAccountNbr,
	bankAccountType,
	isClientPage,
	clientAddress,
	payerPostal,
	payerCity,
	payerState,
	payerLine1,
	invoiceNumber,
	scheduledPaymentId,
	achToken,
	selectedScheduledPayments,
}: Params,
) {
	try {
		const { data } = await axios.post( '/api/processor/payment/createPayment', {
			type,
			amount,
			fee,
			tip,
			note,
			signature,
			orderId,
			gatewayId,
			companyId,
			houseAccountId,
			employeeId,
			paymentId,
			metadata,
			staffExternalId,
			staffId,
			memberCardNumber,
			cardHolderName,
			cardHolderPhone,
			cardFirstSix,
			cardLastFour,
			isPaymentLink,
			payerId,
			payerName,
			payerFirstName,
			payerLastName,
			saveCard,
			cardToken,
			bankRoutingNbr,
			bankAccountNbr,
			bankAccountType,
			isClientPage,
			clientAddress,
			payerPostal,
			payerCity,
			payerState,
			payerLine1,
			invoiceNumber,
			scheduledPaymentId,
			achToken,
			selectedScheduledPayments,
			...args,
		} );
		
		return data;
	} catch ( error ) {
		if ( fee > 0 ) {
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/tempCardFee`, {
				id     : orderId,
				cardFee: fee,
				remove : true,
				company: companyId,
				staffId,
			} ).catch( () => [] );
		}
		throw error;
	}
	
}

type ClientPaymentType = {
	type: string | undefined,
	amount: number,
	signature?: string,
	note?: string,
	orderId: string,
	gatewayId: string | undefined,
	companyId: string,
	selectedClientPayment: Payment,
	metadata: any,
	staffExternalId?: string | undefined,
	staffId: string | undefined,
	payerId?: string | undefined,
	payerName?: string,
	isClientPage?: boolean,
	clientAddress?: Address,
	invoiceNumber?: string
	
};

export async function makePaymentWithClientPayment( paymentData: ClientPaymentType ) {
	try {
		const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/createPayment`, paymentData );
		
		return data;
	} catch ( error ) {
		// debugger;
		throw error;
	}
}

export function cardValidation( requiredCardInfo: boolean,
	enqueueSnackbar: any,
	cardHolderName?: string, cardHolderPhone?: string,
	saveCard?: boolean, cardFirstSix?: string, cardLastFour?: string ) {
	
	if ( requiredCardInfo ) {
		let message;
		if ( !cardHolderName ) {
			message = 'Card holder name is required.';
		} else if ( cardHolderName?.length < 3 ) {
			message = 'Please enter your full name';
		} else if ( !cardHolderPhone ) {
			message = 'Card holder phone is required';
		} else if ( cardHolderPhone?.length < 9 ) {
			message = 'Please enter your full phone number';
		}
		if ( message ) {
			enqueueSnackbar( message, { variant: 'info' } );
		}
		return message;
	}
	if ( saveCard ) {
		let saveCardMessage;
		if ( cardFirstSix?.length !== 6 ) {
			saveCardMessage = 'Card first six must be 6 digits.';
		} else if ( cardLastFour?.length !== 4 ) {
			saveCardMessage = 'Card last four must be 4 digits';
		}
		if ( saveCardMessage ) {
			enqueueSnackbar( saveCardMessage, { variant: 'info' } );
		}
		return saveCardMessage;
		
	}
}

export async function makePurchasePayment(
	type: string, amount: number, tip: number,
	note: string, signature: string,
	purchaseId: string, gatewayId: string, companyId: string, metadata: any, payerId?: string, payerName?: string ) {
	
	const { data } = await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/payment/createPurchasePayment`, {
		type,
		amount,
		tip,
		note,
		signature,
		purchaseId,
		gatewayId,
		companyId,
		metadata,
		payerId,
		payerName,
	} );
	
	return data;
	
}

export function getTotalAfterCardFee( invoice: Order, cashDiscount: number, cardFee: number ) {
	const [ discounts, fees ] = partition( ( invoice.prices || [] )
		.filter( ( price ) => !price.metadata?.cloverTaxPercent ), ( { value } ) => value < 0 );
	
	const discountTotal = [ ...discounts,
		cashDiscount > 0
			? { isPercent: true, value: -( cashDiscount * 100 ), quantity: 1 }
			: null ].filter( Boolean ).reduce( ( sum, price ) => sum + ( price?.isPercent
		? price?.value * invoice.subTotal / 100
		: ( price?.value || 0 ) * ( price?.quantity || 0 ) ), 0 );
	const subTotal = invoice.subTotal + discountTotal;
	
	const feeTotal = [ ...fees,
		cardFee > 0 ? { isPercent: true, value: cardFee * 100, quantity: 1 } : null ].filter( Boolean )
		.reduce( ( sum, price ) => sum + ( price?.isPercent
			? price?.value * subTotal / 100
			: ( price?.value || 0 ) * ( price?.quantity || 0 ) ), 0 );
	return { additionalPrices: discountTotal + feeTotal, discountTotal, feeTotal };
}

export function getModifiersTotals( lineItems: LineItem[] ) {
	if ( isEmpty( lineItems ) ) return [];
	return lineItems?.map( ( lineItem ) => {
		let total = 0;
		const modifiers = flatten( lineItem.modifierGroups?.map( ( mGroup ) => mGroup?.modifiers
			?.filter( ( modifier ) => modifier.externalId && lineItem.metadata?.[ modifier.externalId ] ) ) );
		total += modifiers?.reduce( ( sum, modifier ) => sum + ( modifier?.isPercent
			? modifier?.value * total / 100
			: ( modifier?.value || 0 ) * ( modifier?.externalId ? lineItem.metadata?.[ modifier.externalId ] : 0 ) ), 0 );
		return total || 0;
	} );
}

export function getLineItemsTotals( lineItems: LineItem[], modifierTotals: number[] ) {
	return lineItems.map( ( lineItem, index ) => {
		let total = lineItem.price * lineItem.quantity + ( modifierTotals[ index ] || 0 ) * lineItem.quantity;
		const [ discounts, fees ] = partition( lineItem.prices
			?.filter( ( price ) => !price.metadata?.externalTax ), ( { value } ) => value < 0 );
		total += discounts.reduce( ( sum, price ) => sum + ( price?.isPercent
			? price?.value * total / 100
			: price?.value * price.quantity * lineItem.quantity ), 0 );
		total += fees.reduce( ( sum, price ) => sum + ( price?.isPercent
			? price?.value * total / 100
			: price?.value * price.quantity ), 0 );
		return total || 0;
	} );
}

export function getOrderTaxTotal( totals: number[],
	lineItems: LineItem[],
	invoice: Pick<Order, 'prices' | 'taxPercent'>,
	orderSubtotal: number,
	cashDiscountPercent?: number ) {
	let additionalPrices: number = 0;
	let additionalDiscountTotal: number = 0;
	let additionalFeeTotal: number = 0;
	const [ discounts, fees ] = partition( ( invoice.prices || [] )
		.filter( ( price ) => !price.metadata?.cloverTaxPercent ), ( { value } ) => value < 0 );
	
	const lineItemHasCashDiscount = Boolean( lineItems?.find( ( lineItem ) => ( lineItem.cashDiscount || 0 ) > 0 ) );
	
	const cashDiscountDollarValue = lineItemHasCashDiscount
		? lineItems.reduce( ( acc,
			lineItem ) => acc + ( lineItem.cashDiscount || 0 ) * lineItem.quantity, 0 )
		: 0;
	
	const orderDiscounts = [ ...discounts,
		( cashDiscountPercent || 0 ) > 0
			? {
				isPercent: !lineItemHasCashDiscount,
				value    : lineItemHasCashDiscount
					? -cashDiscountDollarValue
					: -( cashDiscountPercent || 0 ),
				quantity: 1,
			}
			: null ].filter( Boolean );
	
	const orderFees = [ ...fees ].filter( Boolean );
	
	const orderTaxTotal = Math.max( round( totals.reduce( ( sum, total, index ) => {
		const discountTotal = orderDiscounts?.reduce( ( sum, price ) => sum + ( price?.isPercent
			? price?.value * total / 100
			: ( price?.value || 0 ) * ( price?.quantity || 0 ) * ( total / orderSubtotal ) ), 0 );
		const subTotal = total + ( discountTotal || 0 );
		const lineItem = lineItems[ index ];
		const lineItemTaxes = lineItem?.prices?.filter( ( price ) => price.metadata?.useTax );
		const [ percentTaxes, dollarTaxes ] = partition( lineItemTaxes, ( tax ) => tax.isPercent );
		let lineItemsPercentTaxTotal = 0;
		if ( !isEmpty( percentTaxes ) ) {
			lineItemsPercentTaxTotal = percentTaxes.reduce( ( sum, tax ) => sum + tax.value, 0 );
		}
		let lineItemsDollarTaxTotal = 0;
		if ( !isEmpty( dollarTaxes ) ) {
			lineItemsDollarTaxTotal = dollarTaxes.reduce( ( sum, tax ) => sum + tax.value, 0 );
		}
		const feeTotal = orderFees?.reduce( ( sum, price ) => sum + ( price?.isPercent
			? price?.value * subTotal / 100
			: price?.value * price.quantity / totals.length ), 0 );
		additionalPrices += discountTotal + ( feeTotal || 0 );
		additionalDiscountTotal += discountTotal || 0;
		additionalFeeTotal += feeTotal || 0;
		return sum + lineItemsDollarTaxTotal * lineItem.quantity + subTotal * ( ( lineItems[ index ].tax === null
			? invoice.taxPercent
			: lineItems[ index ].tax || lineItemsPercentTaxTotal ) || 0 ) / 100;
	}, 0 ), 2 ), 0 );
	
	return {
		orderTaxTotal,
		additionalPrices,
		additionalDiscountTotal,
		additionalFeeTotal,
	};
}

export function getTotals( lineItems: LineItem[] ) {
	const modifierTotals = getModifiersTotals( lineItems as LineItem[] );
	const totals = getLineItemsTotals( lineItems as LineItem[], modifierTotals );
	const orderSubTotal = round( sum( totals ), 2 );
	return { totals, orderSubTotal };
}

export function getPayingTotalAfterCashDiscount( invoice: Order, cashDiscountPercent: number ) {
	if ( !cashDiscountPercent ) return { orderGrandTotal: 0, orderSubTotal: 0 };
	
	const lineItems = invoice.lineItems?.filter( ( lineItem ) => !lineItem.metadata?.refunded );
	const { totals, orderSubTotal } = getTotals( lineItems as LineItem[] );
	const taxTotals = getOrderTaxTotal( totals, lineItems as LineItem[], invoice, orderSubTotal, cashDiscountPercent );
	const orderGrandTotal = round( orderSubTotal + ( taxTotals?.additionalPrices || 0 ) + ( taxTotals?.orderTaxTotal || 0 ), 2 ) - ( invoice.paidTotal || 0 );
	return { orderSubTotal, orderGrandTotal };
	
}

export function getTotalAfterEmployeeCredit( invoice: Order, absorbValueDiscount: Price ) {
	const lineItems = invoice.lineItems?.filter( ( lineItem ) => !lineItem.metadata?.refunded );
	const { totals, orderSubTotal } = getTotals( lineItems as LineItem[] );
	
	const taxTotals = getOrderTaxTotal( totals, lineItems as LineItem[], {
		...invoice,
		prices: [ ...invoice.prices || [], absorbValueDiscount || null ].filter( Boolean ),
	}, orderSubTotal );
	const orderGrandTotal = round( orderSubTotal + ( taxTotals?.additionalPrices || 0 ) + ( taxTotals?.orderTaxTotal || 0 ), 2 ) - ( invoice.paidTotal || 0 );
	return { orderSubTotal, orderGrandTotal };
	
}

export function getOrderTotalDiscountAndFees( invoice: Pick<Order, 'lineItems' | 'prices' | 'taxPercent'> ) {
	const lineItems = invoice.lineItems?.filter( ( lineItem ) => !lineItem.metadata?.refunded );
	const modifierTotals = getModifiersTotals( lineItems as LineItem[] );
	const totals = getLineItemsTotals( lineItems as LineItem[], modifierTotals );
	const orderSubTotal = round( sum( totals ), 2 );
	const taxTotals = getOrderTaxTotal( totals, lineItems as LineItem[], invoice, orderSubTotal );
	return { totalDiscount: taxTotals?.additionalDiscountTotal || 0, totalFees: taxTotals?.additionalFeeTotal || 0 };
}

export function getCardFeeAmount( invoice: Order, method: string, cardType: string, multiPay?: boolean ) {
	if ( !invoice ) return 0;
	if ( !multiPay && invoice?.metadata?.cardFee ) return 0;
	if ( !multiPay && ( invoice.metadata?.enableCardFee || !invoice.company.metadata?.cardFee ) ) return 0;
	if ( !( method === 'card' || method?.includes( 'saved' ) ) ) return 0;
	if ( cardType === 'debit' && !invoice.company.metadata?.includeDebitCardFee ) return 0;
	return round( invoice.company.metadata.cardFee || 0, 2 ) / 100;
}

export function getCashDiscountPercent( invoice: Order, company: Company, method: string ) {
	if ( method === 'card' || method?.includes( 'saved' ) || method === 'invoiss' ) {
		return 0;
	}
	if ( invoice.paidTotal === 0 && invoice.metadata?.enableCashDiscount && company?.metadata?.cashDiscount ) {
		return company?.metadata?.cashDiscount || 0;
	} else {
		return 0;
	}
	
}

export type StorePaymentOptionsType = {
	processing_fee: boolean,
	bank_transfer: boolean,
	card: boolean,
	invoice_me?: boolean
	
};

export function eligibleForBankTransfer(
	method: 'bank_transfer',
	storePaymentOptions: StorePaymentOptionsType,
	gateways?: Array<Pick<GatewayBase, 'external'>> ) {
	
	const cardConnect = gateways?.find( ( gateway ) => gateway.external === 'CARD_CONNECT' );
	const stripe = gateways?.find( ( gateway ) => gateway.external === 'STRIPE' );
	
	if ( method === 'bank_transfer' ) {
		return storePaymentOptions?.bank_transfer ?? Boolean( cardConnect || stripe );
	}
	
}

export async function deleteStoreOrderOnPaymentFailure( orderId: string, queryClient: any, companyId: string ) {
	try {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manage/deleteOrder`, { id: orderId } );
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/orderPublicWrite`, {
			id          : orderId,
			paid        : false,
			paidTotal   : 0,
			invoiceTotal: 0,
			externalId  : null,
			companyId   : companyId,
		} );
		await queryClient.invalidateQueries( [ 'order' ] );
		
	} catch {
	}
}

export async function sendEmailsAfterPayments( order: Order, payment: Payment, paymentAmount: number ) {
	const { staff } = getUserInfo();
	
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/paymentReceipt`, {
		invoice: order,
		payment: {
			...payment,
			amount: paymentAmount > 0 ? paymentAmount : payment.amount,
		},
		client: order?.client,
	} );
	if ( order.staff?.email && order.staff?.email !== order.company.email && staff?.permissions?.includes( 'SEND_EMAIL_NOTIFICATION' ) ) {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/paymentReceipt`, {
			invoice: order,
			payment: {
				...payment,
				amount: paymentAmount > 0 ? paymentAmount : payment.amount,
			},
			client    : order?.client,
			staffEmail: order.staff?.email,
		} );
	}
	if ( order?.client ) {
		await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/clientPaymentReceipt`, {
			invoice: order,
			payment: {
				...payment,
				amount: paymentAmount > 0 ? paymentAmount : payment.amount,
			},
			client: order.client,
		} );
	}
	
}

export async function sendTextMessageAfterPayments( order: Order,
	payment: Payment,
	paymentAmount: number,
	company: Company,
	staff: Staff | undefined,
	newLocationOrder: Order,
	orderClient: any ) {
	const amount = paymentAmount > 0 ? paymentAmount : payment.amount;
	// send text to company when client makes the payment
	try {
		if ( company.cell && !staff ) {
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/message`, {
				company: company.id,
				number : company.cell,
				message: `Hi ${company?.name || company?.contact || ''}\n`
					+ `You just got paid by ${orderClient?.name || orderClient?.contact || 'a client'}\n`
					+ `in amount of ${currencyFormat( amount )}`,
			} );
		}
	} catch {
	}
	try {
		const client = newLocationOrder.client || orderClient;
		if ( client?.cell && !staff ) {
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/message`, {
				company: company.id,
				number : client?.cell,
				message: `Hi ${client.name || client.contact || ''}\n`
					+ `You’ve just made a payment to ${company?.name || company?.contact || 'a company'} for ${currencyFormat( amount )}.
Your payment will be complete once the status updates to PAID.
If the status remains OPEN, please follow up with ${company?.name || company?.contact || 'the company'} for confirmation.`,
			} );
		}
	} catch {
	}
}

