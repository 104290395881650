import { mutateGraphQL, queryGraphQL } from '@/data/apollo';
import { CommercesWrite } from '@/data/commerce/commerce.graphql';
import { InvoiceWrite } from '@/data/commerce/invoice.graphql';
import { PurchaseWrite } from '@/data/commerce/purchase.graphql';
import { AddressRead } from '@/data/management/address.graphql';
import SyncToClover from '@/helpers/syncToClover';
import { isEstimateType, isInvoiceType } from '@/helpers/useIsEstimateType';
import { getUserInfo } from '@/providers/auth/useUserInfo';
import {
	HouseAccount,
	MutationCommercesWriteArgs,
	MutationHouseAccountWriteArgs,
	MutationInvoiceWriteArgs,
	type MutationPurchaseWriteArgs,
	Order,
	Purchase,
	type QueryAddressReadArgs,
	QueryHouseAccountsReadArgs,
	Staff,
} from '@/types/schema';
import { gql } from '@apollo/client';
import axios from 'axios';
import { format } from 'date-fns';
import { isEmpty, isString, startCase, toLower, uniq } from 'lodash-es';
import { Maybe } from 'yup/es/types';
import { StockMethod, updateStock } from '../orders/utils';
import { makePayment } from '../payment/helpers';

export type EmailValuesType = {
	cc: string[],
	bcc: string[],
	to: string[],
	note: string,
	subject: string,
	validateToEmails?: string[],
	validateCCEmails?: string[],
	validateBccEmails?: string[],
	syncAndSend?: boolean,
	toggleEmailPdfUrl?: boolean
};

export const getCurrentInvoicesDates = ( houseAccount: HouseAccount ) => {
	let invoiceDates = houseAccount.invoiceDates || [];
	const newInvoiceDate = format( new Date(), 'MMMM yyyy' );
	invoiceDates = !isEmpty( invoiceDates ) ? uniq( [ ...invoiceDates,
		newInvoiceDate ] )
		: [ newInvoiceDate ];
	return invoiceDates;
};

export const getHouseAccountFromClient = async ( invoice: Order ) => {
	if ( invoice.client?.id ) {
		const { houseAccountsRead } = await queryGraphQL<QueryHouseAccountsReadArgs>( {
			query    : HouseAccountsReadByClient,
			variables: { options: { limit: 1, filter: { client: invoice.client.id } } },
		} );
		return houseAccountsRead?.items?.[ 0 ];
	}
	return null;
	
};

export const getHouseAccountFromInvoice = ( invoice: Order ) => {
	if ( invoice.type !== 'INVOICE' ) return { remainingAmount: 0, houseAccount: undefined };
	const remainingAmount = ( invoice.grandTotal || 0 ) - ( invoice.paidTotal || 0 );
	const houseAccount: boolean | Maybe<HouseAccount> | undefined = invoice.houseAccount;
	return { remainingAmount, houseAccount };
};

export const addHouseAccountAndView = async (
	invoice: Order,
	houseAccount: HouseAccount,
	router ) => {
	
	const amount = invoice.grandTotal || 0 - ( invoice.paidTotal || 0 );
	
	const { invoiceWrite } = await mutateGraphQL<MutationInvoiceWriteArgs>( {
		mutation : InvoiceWrite,
		variables: {
			id    : invoice.id,
			method: 'Add house account',
			input : {
				invoiceTotal: amount,
				houseAccount: houseAccount.id,
			},
		},
	} );
	await router.push( `/dashboard/commerce/invoices/${invoiceWrite.id}` );
};

export const syncHAInvoiceAndPay = async (
	invoice: Order,
	houseAccount: HouseAccount,
	action: string,
	staff: Staff,
	user,
	router,
	enqueueSnackbar?,
	t?,
	setSyncLoading?: ( loading: boolean ) => void ) => {
	try {
		if ( invoice.companyLocation?.gateway?.external !== 'CLOVER' || !invoice.companyLocation?.gateway?.active ) {
			enqueueSnackbar( t( 'commerce:select-a-clover-company-location' ), { variant: 'error' } );
			return;
		}
		const amount = invoice.grandTotal || 0 - ( invoice.paidTotal || 0 );
		
		if ( action === 'Pay with Invoiss' && amount === 0 ) {
			enqueueSnackbar( t( 'commerce:invoice-is-fully-paid-already' ), { variant: 'info' } );
			return;
		}
		setSyncLoading?.( true );
		enqueueSnackbar( t( 'commerce:invoice-is-being-synced' ), { variant: 'info' } );
		
		const invoiceDates = getCurrentInvoicesDates( houseAccount );
		
		await mutateGraphQL<MutationHouseAccountWriteArgs>( {
			mutation: gql`
				mutation HouseAccountWrite_f53b($id: String,$method: String,  $input: HouseAccountValidator,$remove: Boolean) {
					houseAccountWrite(id: $id,   method: $method,input: $input, remove: $remove) {
						id
					}}`,
			variables: {
				id    : houseAccount.id,
				method: 'Saved HouseAccount',
				input : {
					invoiceDates,
				},
			},
		} );
		
		const { invoiceWrite } = await mutateGraphQL<MutationInvoiceWriteArgs>( {
			mutation : InvoiceWrite,
			variables: {
				id    : invoice.id,
				method: action === 'Pay without Invoiss'
					? 'Open on Clover'
					: 'Pay on Clover',
				input: {
					invoiceTotal: amount,
					houseAccount: houseAccount.id,
				},
			},
		} );
		
		let cloverCommerce: Order | undefined = undefined;
		
		if ( invoiceWrite ) {
			const cloverInvoice = await SyncToClover( invoiceWrite );
			cloverCommerce = cloverInvoice?.commerce;
			await updateStock( invoiceWrite, staff.company, 'manualSyncStock', enqueueSnackbar );
		}
		
		if ( action === 'Pay without Invoiss' ) {
			await router.push( `/dashboard/commerce/invoices/${invoiceWrite.id}` );
		} else {
			const gateway = invoice.companyLocation?.gateway;
			if ( cloverCommerce ) {
				await makePayment( {
					type           : 'Invoiss',
					amount         : amount,
					fee            : 0,
					tip            : 0,
					note           : '',
					signature      : null,
					orderId        : invoice.id,
					gatewayId      : gateway.id,
					companyId      : invoice.company.id,
					metadata       : invoice.company?.metadata,
					staffExternalId: staff.externalId || '',
					staffId        : staff.id,
					payerId        : invoice?.client?.id || staff?.id,
					payerName      : user?.firstName,
				} );
				setSyncLoading?.( false );
				
				enqueueSnackbar( t( 'commerce:charge-completed-successfully' ), { variant: 'success' } );
				await router.push( `/dashboard/commerce/invoices/${invoiceWrite.id}` );
			}
			
		}
	} catch ( e ) {
		setSyncLoading?.( false );
		
		const cloverErrors = e?.response?.data?.cloverErrors;
		if ( cloverErrors ) {
			enqueueSnackbar( isString( cloverErrors )
				? cloverErrors
				: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com', { variant: 'error' } );
			return;
		} else {
			enqueueSnackbar( t( 'common:sync-clover-error' ), { variant: 'default' } );
		}
		
		console.error( e );
		throw e;
	}
	
};

export const syncAndUpdateStock = async ( invoice: Order,
	staff: Staff,
	stockMethod: StockMethod,
	enqueueSnackbar,
	t,
	setSyncLoading?: ( loading: boolean ) => void ) => {
	try {
		setSyncLoading?.( true );
		enqueueSnackbar( t( 'commerce:please-wait-while-being-synced', { type: toLower( invoice.type ) } ), { variant: 'info' } );
		await SyncToClover( invoice );
		await updateStock( invoice, staff.company, stockMethod, enqueueSnackbar );
		setSyncLoading?.( false );
		
	} catch ( e ) {
		setSyncLoading?.( false );
		const cloverErrors = e?.response?.data?.cloverErrors;
		if ( cloverErrors ) {
			enqueueSnackbar( isString( cloverErrors )
				? cloverErrors
				: cloverErrors?.error?.message || cloverErrors?.message || 'An error has occurred. Clover.com', { variant: 'error' } );
			return;
		} else {
			enqueueSnackbar( t( 'common:sync-clover-error' ), { variant: 'default' } );
		}
		
		console.error( e );
		throw e;
	}
	
};

export const syncQBInvoice = async ( invoice: Order, prices, enqueueSnackbar, t ) => {
	const { staff } = getUserInfo();
	let hasValidationError = false;
	
	if ( !invoice.client ) {
		enqueueSnackbar( t( 'common:add-client-before-sync' ), { variant: 'default' } );
		hasValidationError = true;
	}
	if ( isEmpty( invoice.lineItems ) ) {
		enqueueSnackbar( t( 'common:add-line-item-before-sync' ), { variant: 'default' } );
		hasValidationError = true;
		
	}
	if ( !isEmpty( invoice.lineItems?.map( ( lineItem ) => lineItem.prices?.find( ( price ) => price.value < 0 ) )
		.filter( Boolean ) ) && !invoice.prices?.find( ( price ) => price.value < 0 ) ) {
		enqueueSnackbar( t( 'common:add-discount-before-sync' ), { variant: 'info' } );
		hasValidationError = true;
		
	}
	
	if ( !hasValidationError ) {
		try {
			await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/processor/manageQB/postInvoice`, {
				id          : invoice.id,
				company     : invoice.company.id,
				prices      : prices,
				syncPayments: true,
				staffId     : staff?.id,
			} );
			enqueueSnackbar( 'Successfully synced to QuickBooks', { variant: 'success' } );
			
		} catch {
			enqueueSnackbar( t( 'common:qb-sync-error' ), { variant: 'error' } );
		}
	}
	
};

export const getPurchaseEmailValues = ( purchase: Purchase, staff: Staff ): EmailValuesType => ( {
	cc               : [ staff?.email ].filter( Boolean ),
	bcc              : [],
	validateToEmails : [],
	validateCCEmails : [],
	validateBccEmails: [],
	to               : [ purchase.menu?.vendorEmail ?? '' ].filter( Boolean ),
	note             : `Hi ${purchase.menu?.vendorContact || purchase.menu?.vendorName || 'there'}, \nHere is our order. Please click [View Details] and confirm it.\nThank you`,
	subject          : `Purchase# ${purchase.metadata?.customNumber || purchase.number} from ${purchase.company.name}`,
} );

export const sendPurchaseEmail = async (
	purchase: Purchase,
	timezone: string = 'America/Chicago',
	values: EmailValuesType ) => {
	// get pdf url
	const { data } = await axios.get( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/preview/pdf/${purchase.id}/purchase?getUrl=true&timezone=${timezone}` );
	
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${purchase.id}/purchase`, {
		...values,
		url: data?.url,
	} );
	await mutateGraphQL<MutationPurchaseWriteArgs>( {
		mutation : PurchaseWrite,
		variables: {
			id    : purchase.id,
			method: 'Email Sent',
			input : {
				sent    : true,
				metadata: {
					...purchase.metadata,
					sentDates: [ ...!isEmpty( purchase.metadata?.sentDates )
						? [ ...purchase.metadata.sentDates, new Date() ]
						: [ new Date() ] ],
				},
			},
		},
	} );
};

export const getCommerceEmailValues = ( staff: Staff, invoice: Order, subject?: string ): EmailValuesType => {
	const isOrder = invoice.type === 'ORDER';
	const isInvoice = isInvoiceType( invoice.type );
	const clientName = ( invoice.client?.contact?.split( ' ' )[ 0 ]?.length || 0 ) > 2
		? invoice.client?.contact
		: invoice.client?.name || invoice.client?.email || invoice.houseAccount?.name || invoice.houseAccount?.email || '';
	
	const customInvoiceMessage = ( staff?.company.templateData?.invoiceEmailBody || staff?.emailData?.invoiceEmailBody )?.replace( /{clientname}/gi, clientName );
	const customEstimateMessage = ( staff?.company.templateData?.estimateEmailBody || staff.emailData?.estimateEmailBody )?.replace( /{clientname}/gi, clientName );
	const customOrderMessage = staff?.company.templateData?.orderEmailBody?.replace( /{clientname}/gi, clientName || '' );
	
	const editableMessage = `Hi ${clientName || 'there'}, \n${isEstimateType( invoice.type )
		? 'Here is your Estimate. Please click [View Details] to open and approve the estimate.\nWe are excited for the opportunity of working with you.'
		: `Your ${toLower( invoice.type )} is ready. Please click [View Details] to open and pay.\nThank you for choosing ${startCase( toLower( invoice.company.name ) )}.`}`;
	
	const paidReceipt = `Hi ${clientName || 'there'},\nYour ${toLower( invoice.type )} is paid.\nThank you for choosing ${startCase( toLower( invoice.company.name ) )}`;
	
	return {
		cc: [
			staff?.metadata?.addEmailToCC ? staff?.email : '',
			...invoice.client?.accountingEmails
				? invoice.client?.accountingEmails.trim().replace( /\s+/g, '' ).split( ',' )
				: [],
		].filter( Boolean ),
		bcc              : [],
		to               : [ invoice.client?.email || invoice.houseAccount?.email || '' ].filter( Boolean ),
		validateToEmails : [],
		validateCCEmails : [],
		validateBccEmails: [],
		note             : invoice.status === 'PAID' ? paidReceipt : isOrder ? customOrderMessage || editableMessage
			: isInvoice ? customInvoiceMessage || editableMessage
				: customEstimateMessage || editableMessage,
		subject: subject || `${startCase( toLower( invoice.type ) )}# ${invoice.metadata?.customNumber || invoice.externalId || '{{number}} '} from ${invoice.company.name}`,
	};
};

export const sendReminderCommercesEmail = async (
	invoices: Order[],
	timezone: string = 'America/Chicago',
	values: EmailValuesType ) => {
	
	await axios.post( `${process.env.NEXT_PUBLIC_SERVER_URL}/api/emails/${invoices[ 0 ].company.id}/reminderInvoices`, {
		...values,
		invoiceIds: invoices.map( ( { id } ) => id ),
		timezone,
	} );
	
	await mutateGraphQL<MutationCommercesWriteArgs>( {
		mutation : CommercesWrite,
		variables: {
			method: 'Sent Reminder',
			inputs: invoices.map( ( invoice ) => ( {
				id      : invoice.id,
				sent    : true,
				metadata: {
					...invoice.metadata,
					sentDates: [ ...!isEmpty( invoice.metadata?.sentDates )
						? [ ...invoice.metadata.sentDates, new Date() ]
						: [ new Date() ] ],
				},
			} ) ),
		},
	} );
};

export const HouseAccountsReadByClient = gql`query HouseAccountsRead_6ed2($options: FilterOptions) {
	houseAccountsRead(options: $options) {
		items {
			id
		}
		count
	}}`;

export const checkIfAddressExist = async ( addressId: string, type?: string ) => {
	try {
		await queryGraphQL<QueryAddressReadArgs>( {
			query    : AddressRead,
			variables: { id: addressId },
		} );
		return null;
	} catch {
		return `The selected client ${type || ''} address may have been removed.`;
	}
	
};
